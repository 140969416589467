const details = [
  {
    path: "home",
    label: "Home",
  },
  {
    path: "feature",
    label: "Products",
  },
];

export default details;
